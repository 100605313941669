import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { getPageContent } from './api'

const Rentals = props => {
  const [disclaimerContent, setRentalsContent] = useState('')

  useEffect(() => {
    getRentalsContent()
  }, [])

  const getRentalsContent = () => {
    getPageContent('rentals', (data) => setRentalsContent(data[0].value))
  }

  return (
    <div className="main-body column">
      <Helmet>
        <meta charSet="utf-8" />
        <meta property="og:title" content="Rentals | Kellands Real Estate"/>
        <title>Rentals | Kellands Real Estate</title>
      </Helmet>
 
      <div className="rentals-container">

      <div className="row">
           <div className="rentals-sub">
            <h1>Property Management</h1>
            <div class="rentals-p-container">
                <p className="rentals-multi-line rentals-p">You're not just another number,&nbsp;</p>
                <p className="rentals-multi-line rentals-p">get the attention you deserve.</p>
                <p className="rentals-one-line rentals-p">You're not just another number, get the attention you deserve.</p>
            </div>
        </div>
        <div className="rentals-text">
          <p className="about-text"
            dangerouslySetInnerHTML={{ __html: disclaimerContent }}>
          </p>
        </div>
      </div>
      <div className="logos-container">
            <div className="logo-container">
                <img className="rental-logo" height={25} src="/images/mri-logo.svg" alt="MRI logo" />
            </div>
               <div className="logo-container">
                <img className="rental-logo" height={25} src="/images/trademe-logo.png" alt="TradeMe logo" />
            </div>
               <div className="logo-container">
                <img className="rental-logo" height={25} src="/images/renti.png" alt="Renti logo" />
            </div>
               <div className="logo-container">
                <img className="rental-logo" height={25} src="/images/re-logo.svg" alt="RE logo" />
            </div>
               <div className="logo-container">
                <img className="rental-logo" id="centrix-logo" height={25} src="/images/Centrix-Logo.png" alt="Centrix logo" />
            </div>
      </div>
      </div>

    </div>
  )
}

export default Rentals