import React from 'react'
import { Slide } from 'react-slideshow-image';

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const ImageModal = props => {
  const slideShowProperties = {
    duration: 5000,
    autoplay: false,
    transitionDuration: 500,
    arrows: true,
    infinite: true,
    easing: "ease",
    defaultIndex: props.modal.idx,
  }

  return (
    <div className="kpod-modal-container"
      style={{ marginTop: props.modal.topOffset + 'px' }}>
      <i className="fa fa-times-circle functions-btn"
        aria-hidden="true"
        style={{
          padding: '5px',
          // color: 'rgba(0, 0, 0, 0.77)',
          color: 'rgba(241, 241, 241, 0.82)',
          cursor: 'pointer',
          position: 'absolute',
          right: 0,
          marginRight: '5px',
          fontSize: '45px',
          zIndex: '14'
        }}
        onClick={() => props.handleModal(false, null, 0)}
      ></i>
      <Slide {...slideShowProperties}>
        {props.images.map((image, i) => {
          return (
            <img src={image.url || `${cloudFrontURL}/properties/${props.id}/images/${image.storageKey}`}
              className="kpod-modal-image"
              alt=""
            // onError={() => { if (this.src != 'error.jpg') this.src = 'error.jpg' }}
            />
          )
        })
        }
      </Slide>
    </div>
  )

}

export default ImageModal