import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet'
import { getPageContent } from './api'

const Promotion = props => {
    
  const [promotionContent, setPromotionContent] = useState('')
  const history = useHistory();
  
  useEffect(() => {
    getPromotionStatus()
    getPromotionContent()
  }, [])

    const getPromotionStatus = () => {
        // redirect to homepage if promotion is not active
    getPageContent('status-promotion', (data) => {
        if (data.length) {
            if (data[0].value === 'inactive') {
             history.push("/");
            }
        } else {
             history.push("/");
        }
    } )
  }


  const getPromotionContent = () => {
    getPageContent('promotion', (data) => {
        if (data.length) {
        setPromotionContent(data[0].value)
        } else {
        }
    } )
  }

  
      

  return (
    <div className="main-body column">
        <Helmet>
            <meta charSet="utf-8" />
            <meta property="og:title" content='Promotion | Kellands Real Estate'/>
            <title>Promotion | Kellands Real Estate</title>
        </Helmet>
        <div className="promotion-container">
            <div className="promotion-header-image-container">
                <img className="promotion-header-image"
                    src="/images/top.jpg"
                    alt="Te Arai Links" />

            </div>
            <div className="promotion-top-text-container">
                <div className="row">
                <p class="about-text">
                    <h1>WIN A WEEKEND GETAWAY AT TE ARAI LINKS</h1>
                    <p>Have your property appraised, listed, sold or a new property managed with us and be in to win a weekend getaway at world class golf resort Te Arai Links, valued at $6,000*.</p>
                    <p>Kellands is a boutique real estate agency with a close-knit team of dedicated agents. Passionate about marketing, exceptional service and delivering extraordinary results, we are not the status quo.</p>
                    <p>Get in touch to book your appraisal. <a href="/ourpeople">Click here</a> to find your Kellands team member.</p>
                </p>
            </div>
            </div>
            <div className="promotion-middle-images-container">
                <div className="ml-image-container">
                    <img className="promotion-middle-image"
                        src="/images/ml.jpg"
                        alt="Te Arai Links" />
                </div>
                <div className="ml-image-container">
                    <img className="promotion-middle-image"
                        src="/images/mr.jpg"
                        alt="Te Arai Links" />
                </div>

            </div>
            <div className="promotion-bottom-text-container">
        <div className="row">

                <p className="about-text">
                    <h2>*Terms and Conditions:</h2>
                    <ul>
                        <li>The competition runs from 1st September 2023 to 31st January 2024.</li>
                        <li>The prize is a $6,000 voucher for Te Arai Links (incl. GST).</li>
                        <li>The winner will be drawn week commencing 1st February 2024 and notified by phone and/or email.</li>
                        <li>Only one winner will be drawn.</li>                                             
                        <li>The prize voucher can be used towards accommodation, golf, dining and in-accommodation massage and beauty treatments at Te Arai Links. Valid for 36 months from the date of purchase.</li>
                        <li>The promotion is open to those who, during the period of 1st September 2023 to 31st January 2024, engage with Kellands Real Estate to; appraise their property, sell their property (including if listed prior to 1st September 2023), sign a Sole Agency Agreement, renew an existing Sole Agency Agreement, or list their rental property with our property management team during the period of the promotion.</li>
                        <li>To be eligible for entry by appraisal, it must be formal/written in nature and the Kellands agent must receive the opportunity to view the property in person accompanied by the legal owner of the property in order to accurately appraise the value.</li>
                        <li>Up to a maximum of 3 entries per person can be gained; with an appraisal, listing, sale and rental listing each being eligible for 1 entry if achieved within the promotion timeframe.</li>
                        <li>Prizes can be transferable, however are not exchangeable and cannot be redeemed for cash; this decision is at the sole discretion of Kellands Real Estate.</li>
                        <li>By registering your contact details with Kellands Real Estate you provide consent to be contacted for marketing and/or listing purposes.</li>
                        <li>By accepting the prize, the winners agree Kellands Real Estate can promote the prize has been won both online and in print.</li>
                    </ul>
                </p>

            </div>
            </div>
            <div className="promotion-bottom-image-container">
                <img className="promotion-bottom-image"
                    src="/images/bot.jpg"
                    alt="Te Arai Links" />

            </div>
        </div>

        <div className="row">
            {/* <div className="Promotion-text">
            <p className="about-text"
                dangerouslySetInnerHTML={{ __html: promotionContent }}>
            </p>
            </div> */}
        </div>
    </div>
  )
}

export default Promotion