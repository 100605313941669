import React, { useState, useEffect } from 'react'

const PagePagination = props => {
  const itemsPerPage = 12
  const [paginationPage, setPaginationPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    setTotalPages(Math.ceil(props.count / itemsPerPage))
  }, [props.count, itemsPerPage])

  const handleFirstPage = () => {
    setPaginationPage(1)
    props.setOffset(0)
  }

  const handlePrevPage = () => {
    let newOffset = (props.offset) - itemsPerPage
    props.setOffset(newOffset)
    setPaginationPage(paginationPage - 1)
  }

  const handleNextPage = () => {
    let newOffset = (props.offset) + itemsPerPage
    props.setOffset(newOffset)
    setPaginationPage(paginationPage + 1)
  }

  const handleLastPage = () => {
    let newOffset = (itemsPerPage * totalPages) - itemsPerPage
    props.setOffset(newOffset)
    setPaginationPage(totalPages)
  }

  return (
    <div id="pagination-container" className="row">
      <i className={`fa fa-angle-double-left
      ${paginationPage === 1 && 'btn-disabled'}`}
        aria-hidden="true"
        style={{
          padding: '5px',
          color: 'rgb(94, 35, 81)',
          cursor: 'pointer',
          fontSize: '36px',
          margin: '0px 10px 0px 10px'
        }}
        onClick={() => { paginationPage !== 1 && 'btn-disabled' && handleFirstPage() }}>
      </i>
      <i className={`fa fa-angle-left
      ${paginationPage === 1 && 'btn-disabled'}`}
        aria-hidden="true"
        style={{
          padding: '5px',
          color: 'rgb(94, 35, 81)',
          cursor: 'pointer',
          fontSize: '36px',
          margin: '0px 10px 0px 10px'
        }}
        onClick={() => { paginationPage !== 1 && 'btn-disabled' && handlePrevPage() }}>
      </i>
      <div className="main-pagination-text">
        Page {paginationPage} of {totalPages}
      </div>
      <i className={`fa fa-angle-right
      ${paginationPage === totalPages && 'btn-disabled'}`}
        aria-hidden="true"
        style={{
          padding: '5px',
          color: 'rgb(94, 35, 81)',
          cursor: 'pointer',
          fontSize: '36px',
          margin: '0px 15px 0px 15px'
        }}
        onClick={() => { paginationPage !== totalPages && 'btn-disabled' && handleNextPage() }}>
      </i>
      <i className={`fa fa-angle-double-right
      ${paginationPage === totalPages && 'btn-disabled'}`}
        aria-hidden="true"
        style={{
          padding: '5px',
          color: 'rgb(94, 35, 81)',
          cursor: 'pointer',
          fontSize: '36px',
          margin: '0px 10px 0px 10px'
        }}
        onClick={() => { paginationPage !== totalPages && 'btn-disabled' && handleLastPage() }}>
      </i>
    </div >
  )
}

export default PagePagination