import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import MCETextEditor from './MCETextEditor.jsx'
import { Alert, AlertTitle } from '@material-ui/lab'
import IconButton from '@material-ui/core/IconButton';

import { getData, getPageContent, patchPropertyFeatured, updatePageContent } from '../api.js'

import { getArrObjCopy, getObjCopy } from '../getCopies'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const Web = props => {
  const [featuredProperties, setFeatured] = useState([])
  const [featuredOriginal, setOriginalFeatured] = useState([])
  const [listOptions, setListOptions] = useState([])
  const [listLoaded, setListLoaded] = useState(false)
  const [selected, setSelected] = useState({ item: listOptions[0], idx: 0 })
  const [error, setError] = useState(false)
  const [tab, setTab] = useState('privacy')
  const [tabContent, setTabContent] = useState({ type: '', content: '', id: 0 })
  const [status, setPromotionStatus] = useState('')

  const [saveWebContentSuccess, setSaveContentSuccess] = useState(false)

  useEffect(() => {
    let featureUrl = `admin/properties?featured=true`
    getData(featureUrl, handleFeaturedData, handleError)
    // getData(listUrl, (d) => handleListData(d, setFeatured), handleError)
  }, [props])

  useEffect(() => {
    if (!listLoaded) {
      let listUrl = `admin/properties?status=live&featured=false`
      getData(listUrl, handleListData, handleError)
    }
    if (!selected.item && listLoaded && listOptions[0]) {
      setSelected({ item: listOptions[0].item, idx: 0 })
    }
  }, [listOptions, listLoaded, selected])

  useEffect(() => {
    if (tabContent.type !== tab) {
        if (tab === "promotion") {
          getPageContent("status-promotion", (d) => handlePromoStatusContent(d), handleError)
        }
      getPageContent(tab, (d) => handleTabContent(d), handleError)
      setSaveContentSuccess(false)
      setError(false)
    }
  }, [tab, tabContent])

  const handleTabContent = (data) => {
    if (data.length) {
      setTabContent({ type: data[0].name, content: data[0].value, id: data[0].id })
    } else {
      setTabContent({ type: tab, content: "" })
    }
  }


  const handlePromoStatusContent = (data) => {
    if (data.length) {
      setPromotionStatus(data[0].value)
    } else {
      setPromotionStatus("inactive")
    }
  }

  const setWebContent = (content) => {
    setTabContent({ type: tab, content: content, id: tabContent.id })
  }

  const saveWebContent = () => {

    if (tabContent.type === "promotion") {
        let promoStatusData = {
          "name": "status-promotion",
          "type": "data",
          "value": status,
          "storageKey": null
        }

    updatePageContent(8, promoStatusData, handlePromoStatusSuccess, handleError)

    }

    let data = {
      "name": tabContent.type,
      "type": "data",
      "value": tabContent.content,
      "storageKey": null
      // null? 
    }
    updatePageContent(tabContent.id, data, handleWebContentSuccess, handleError)
  }

  const handleWebContentSuccess = () => {
    setSaveContentSuccess(true)
  }

   const handlePromoStatusSuccess = (data) => {
  }

  const handleListData = (data) => {
    setListLoaded(true)
    let formatted = formatData(data)
    let newList = []
    formatted.forEach(d => {
      if (!featuredProperties.find(ft => {
        return ft.item.id === d.item.id
      })) { newList.push(d) }
    })
    setListOptions(newList)
  }

  const handleFeaturedData = (data) => {
    let formatted = formatData(data)
    setFeatured(formatted)
    setOriginalFeatured(formatted)
  }

  const formatData = (data) => {
    let formatted = []
    data.forEach(d => {
      let newD = { item: {} }
      newD.item.id = d.id
      newD.item.image = d.images.find(img => img.position === 1)
      // TO DO - handle featured on

      // newD.featuredOn = d.featuredOn
      newD.item.locDescription = d.locDescription
      newD.item.address = d.address
      // <div className="column">
      //                   <div className="bold uppercase">
      //                     {`${ l.address.suburb }, `}
      //                   </div>
      //                   {`${ l.address.streetNumber } ${ l.address.street }`}
      //                 </div>
      formatted.push(newD)
    })
    return formatted
  }

  const handleError = (err) => {
    setError(err)
  }

  const handleSetSelected = (item, idx) => {
    let newItem = { item, idx }
    setSelected(newItem)
  }

  const addFeaturedProperty = (selectedItem) => {
    let selectedItemCopy = getObjCopy(selectedItem)
    let newFeatured = getArrObjCopy(featuredProperties)
    let newList = getArrObjCopy(listOptions)


    newList.splice(selectedItemCopy.idx, 1)
    newFeatured.push(selectedItemCopy)

    setFeatured(newFeatured)
    setListOptions(newList)

    setSelected({ item: newList[0].item, idx: 0 })

    // if (!selected) setSelected({ item: newList[0].item, idx: 0 })
  }

  const removeFeaturedProperty = (item, idx) => {
    let newFeatured = getArrObjCopy(featuredProperties)
    let newList = getArrObjCopy(listOptions)

    newFeatured.splice(idx, 1)
    newList.push({ item: item, idx: idx })

    setListOptions(newList)
    setFeatured(newFeatured)

    setSelected({ item: newList[0], idx: 0 })
    // TO DO - remove featured property
  }

  // const reorder = (method, idx) => {
  //   let newArr = getArrObjCopy(featuredProperties)
  //   let toIdx;
  //   let item = newArr[idx]
  //   newArr.splice(idx, 1)
  //   method === 'up'
  //     ? toIdx = (idx - 1)
  //     : toIdx = (idx + 1)
  //   newArr.splice(toIdx, 0, item)
  //   setFeatured(newArr)
  // }

  const handleSave = () => {
    let unfeatured = []
    let newlyFeatured = []
    let featuredArr = getArrObjCopy(featuredProperties)
    // Find removed featured
    featuredOriginal.forEach(og => {
      if (!featuredArr.find(ft => { return ft.item.id === og.item.id })) {
        unfeatured.push(og.item.id)
      }
    })
    unfeatured.forEach(removeId => {
      patchPropertyFeatured(removeId, false, removeFeaturedSuccess, removeFeaturedError)
    })

    featuredArr.forEach(ft => {
      if (!featuredOriginal.find(og => { return og.id === ft.item.id })) {
        newlyFeatured.push(ft.item.id)
      }
    })

    newlyFeatured.forEach(addId => {
      patchPropertyFeatured(addId, true, addFeaturedSuccess, addFeaturedError)
    })
  }

  const removeFeaturedSuccess = () => {
    // TO DO - put property name here for error/success handling?
    console.log('successfully removed featured property')
  }

  const removeFeaturedError = () => {
    console.log('error on removing featured property')
  }

  const addFeaturedSuccess = () => {
    // TO DO - put property name here for error/success handling?
    console.log('successfully added featured property')
  }

  const addFeaturedError = () => {
    console.log('error on adding featured property')
  }


  return (
    <div className="kpod-container">
      <h1 className="kpod-main-title">
        {props.dataFilter === 'contents'
          ? 'My Website'
          : props.dataFilter}
      </h1>
      {(props.dataFilter === 'featured' || props.subPage === 'featured') ?
        <div>
          <div className="kpod-infotext">
            Currently {featuredProperties.length} properties featured. Only the property is the feature on the home page.
            Change the featured property by setting it to the first position.
            Remove the property by clicking the remove property button.
          </div>
          <div className="kpod-subheader">
            Select property to add as feature:
          </div>
          <div className="row">
            <select className="kpod-featured-select" name="featuredlist">
              {listOptions.map((op, i) => {
                return (
                  <option value={op.item} key={op.item.id}
                    onClick={() => handleSetSelected(op.item, i)}>
                    {/* selected={i === selected.idx}> */}
                    {`${op.item.address.suburb}, 
                    ${op.item.address.subNumber ? `${op.item.address.subNumber}/` : ''}${op.item.address.streetNumber} ${op.item.address.street}`}
                  </option>
                )
              })}
            </select>
            <i className={`fa fa-plus-circle functions-btn
             ${!listOptions.length && 'btn-disabled'}`}
              aria-hidden="true"
              style={{
                padding: '5px',
                color: '#707070',
                cursor: 'pointer',
                fontSize: '32px',
                // marginTop: '2px'
              }}
              onClick={() => { listOptions.length && addFeaturedProperty(selected) }} >
            </i>
          </div>
          <div className="column">
            {featuredProperties.map((property, idx) => {
              return (
                <div key={idx} className="kpod-featured-item-container row">
                  {property.item &&
                    <div className="kpod-featured-item row" key={property.item.id}>
                      <div className="kpod-item-l">
                        <img src={property.item.image.storageKey &&
                          `${cloudFrontURL}/properties/${property.item.id}/images/${property.item.image.storageKey}`}
                          onError={(e) => { e.target.src = '/images/image-default-agent.png' }}
                          className="kpod-item-img"
                          alt="" />
                      </div>
                      <div className="column kpod-featured-text">
                        <div className="kpod-item-title column">
                          <div className="uppercase">
                            {property.item.address.suburb}
                          </div>
                          <div className="kpod-item-title row">
                            {`${property.item.address.subNumber ? `${property.item.address.subNumber}/` : ''}${property.item.address.streetNumber} ${property.item.address.street}`}
                          </div>
                        </div>
                        {/* <div className="row">Featured on:
                        {property.item.featuredOn &&
                            <div className="bold">
                              {property.item.featuredOn}
                            </div>
                          }
                        </div> */}

                      </div>
                      <div className="right kpod-featured-icons">
                        <Link to={`/ account / properties / edit / ${property.item.id} `} className={`kpod - nav - sub - a`}>
                          <i className="fa fa-pencil-square-o functions-btn"
                            aria-hidden="true"
                            style={{
                              padding: '5px',
                              color: '#707070',
                              cursor: 'pointer',
                              fontSize: '24px'
                            }}>
                          </i>
                        </Link>
                        {/* <i className={`fa fa - arrow - up functions - btn functions - btn
  ${ idx === 0 && 'btn-disabled' } `}
                          aria-hidden="true"
                          style={{
                            padding: '5px',
                            color: '#707070',
                            cursor: 'pointer',
                            fontSize: '24px'
                          }}
                          onClick={() => idx !== 0 && reorder('up', idx)}></i>
                        <i className={`fa fa - arrow - down functions - btn functions - btn
  ${ idx === featuredProperties.length - 1 && 'btn-disabled' } `}
                          aria-hidden="true"
                          style={{
                            padding: '5px',
                            color: '#707070',
                            cursor: 'pointer',
                            fontSize: '24px'
                          }}
                          onClick={() => idx !== featuredProperties.length - 1
                            && reorder('down', idx)}>
                        </i> */}
                        <i className="fa fa-times-circle functions-btn functions-btn"
                          aria-hidden="true"
                          key={idx + 'remove'}
                          style={{
                            padding: '2px',
                            color: '#707070',
                            cursor: 'pointer',
                            fontSize: '28px'
                          }}
                          onClick={() => removeFeaturedProperty(property.item, idx)}
                        ></i>
                      </div>
                    </div>
                  }
                </div>
              )
            })}
          </div>
          <div className="right">
            <button
              className="kpod-form-button right"
              onClick={() => handleSave()}>
              Save
              </button>
          </div>
        </div>
        : <div>
          <div className="kpod-form-tabs">
            <div className={`kpod-form-tab ${tab === 'privacy' && 'active-tab'}`} onClick={() => setTab('privacy')}>
              Privacy
            </div>
            <div className="kpod-form-tab form-tab-disabled" onClick={() => setTab('contact')}>
              Contact
            </div>
            <div className={`kpod-form-tab ${tab === 'reaa' && 'active-tab'}`} onClick={() => setTab('reaa')}>
              REA
            </div>
            <div className={`kpod-form-tab ${tab === 'rentals' && 'active-tab'}`} onClick={() => setTab('rentals')}>
              Rentals
            </div>
            <div className={`kpod-form-tab ${tab === 'footer' && 'active-tab'}`} onClick={() => setTab('footer')}>
              Footer
            </div>
            <div className={`kpod-form-tab ${tab === 'disclaimer' && 'active-tab'}`} onClick={() => setTab('disclaimer')}>
              Disclaimer
            </div>
             <div className={`kpod-form-tab ${tab === 'promotion' && 'active-tab'}`} onClick={() => setTab('promotion')}>
              Promotion
            </div>
          </div>
          <div className="kpod-form-outer">
            <div className="row kpod-form-header-info">
              <div>
              {tab.toUpperCase()}
              </div>  
               { tab === 'promotion' &&
              <div className="kpod-form-infotext infotext-column promotion-status-dropdown">
                <div className="kpod-form-infotext-header">
                  Promotion Status
                </div>
                <select name="Promotion Status"
                  onChange={(e) => setPromotionStatus(e.target.value.toLowerCase())}
                  >
                  <option value="active"
                    selected={status === 'active'}>
                    Active
                  </option>
                  <option value="inactive"
                    selected={status === 'inactive' }>
                    Inactive
                  </option>
                </select>
              </div>
             } 
            </div>
               
            <div className="kpod-form-inner">
              <MCETextEditor
                description={tabContent.content}
                setDescription={(desc) => setWebContent(desc)}
              />
            </div>
            <div className="row right">
              <div className="column">
                {error && error.error_description ?
                  <Alert severity="error"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small">
                      </IconButton >
                    }>
                    <AlertTitle className="alert-title">
                      {error.error_description}
                    </AlertTitle>
                  </Alert >
                  : <div />}
                {saveWebContentSuccess ?
                  <Alert severity="success"
                    action={
                      <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small">
                      </IconButton >
                    }>
                    <AlertTitle className="alert-title">
                      Web content for {tab} updated successfully
                  </AlertTitle>
                  </Alert >
                  : <div />}
              </div>
              <button className="kpod-form-button" onClick={() => saveWebContent()}>
                Save
            </button>
            </div>
          </div>
        </div>
      }
    </div>
  )

}

export default Web