import React, { useState, useEffect } from 'react'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const Listing = props => {
  const [thumbnail, setThumbnail] = useState('')
  const [altThumbnail, setAltThumbnail] = useState('')

  useEffect(() => {
    let image = props.property.images.find(i => i.position === 1)
    let ogImage = image.storageKey
    let newImage;
    if (ogImage.includes('_1280-0')) {
      newImage = ogImage.replace('_1280-0', '_300-0')
    } else {
      newImage = ogImage.split('.').join('_300-0.')
    }
    setThumbnail(`${cloudFrontURL}/properties/${props.property.id}/images/${newImage}`)
    setAltThumbnail(`${cloudFrontURL}/properties/${props.property.id}/images/${ogImage}`)
  }, [props])

  return (
    <div className="column">
      <div className="listing-content">
        {props.property.status === 'sold' ?
          <div className="listing-img">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 100 100" preserveAspectRatio="none"
              className="image-triangle triangle-sm">
              <polygon points="0,0 100,0 50,100"></polygon>
            </svg>
            <span className="property-sold-sm">Sold</span>
            <img className="listing-img"
              // src={thumbnail}
              src={altThumbnail}
              // onError={(e) => e.target.src = altThumbnail}
              alt="" />
          </div>
          :
          <div className="listing-img">
            <img className="listing-img"
              // src={thumbnail}
              src={altThumbnail}
              // onError={(e) => e.target.src = altThumbnail}
              alt="" />
          </div>}
        <div className="listing-info-container">
          <div className="listing-text-container">
            <div className="listing-main-text">
              {props.property.address.suburb}
            </div>
            <div className="listing-sub-text">
              {props.property.address.subNumber ? `${props.property.address.subNumber}/` : ''}
              {`${props.property.address.streetNumber} `}
              {props.property.address.street}
            </div>
          </div>

          <div className="listing-count-container row">
            {props.property.bedrooms ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="listing-feature-text">
                  {props.property.bedrooms}
                  <div className="listing-feature-icon">
                    <i className="fa fa-bed" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.bathrooms ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="listing-feature-text">
                  {props.property.bathrooms}
                  <div className="listing-feature-icon">
                    {/* <i className="fa fa-bath" /> */}
                    <img style={{ width: '12px' }} src="/images/bathroom.png" alt="bathroom" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.garages ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="listing-feature-text">
                  {props.property.garages}
                  <div className="listing-feature-icon">
                    <img className="listing-feature-icon"
                      style={{ marginTop: '0px', height: '14px' }}
                      src="/images/garage-parking.svg"
                      alt="garage parking" />
                  </div>
                </div>
              </div> : <div></div>
            }
            {props.property.offstreetParking ?
              <div className="listing-feature">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 120 120" xmlSpace="preserve"
                  className="listing-feature-container">
                  <polygon points="119.5,119.5 0.5,119.5 0.5,0.5 40,0.5 60,20.5 80,0.5 119.5,0.5"
                    className="listing-feature-border">
                  </polygon>
                </svg>
                <div className="listing-feature-text">
                  {props.property.offstreetParking}
                  <div className="listing-feature-icon">
                    <i className="fa fa-car"
                    />
                  </div>
                </div>
              </div> : <div></div>
            }
          </div>
        </div >
      </div >
    </div >
  )
}

export default Listing