import React, { useState, useEffect, useRef } from 'react'
import Slideshow from './Slideshow.jsx'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import GoogleMapReact from 'google-map-react'
import lookup from 'country-code-lookup'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'

import { isServer } from './isServer'
import { formatDate, formatTimes } from './formatDateTimes'
import { openEmail } from './utls.js'
import PropertyNotFound from './PropertyNotFound.jsx'

const cloudFrontURL = 'https://d24y9uuesrc84o.cloudfront.net'

const Property = props => {
  const [propertyNull, setPropertyNull] = useState(false)
  const [property, setProperty] = useState(!isServer
    ? JSON.parse(document.getElementById('propertyinfo').innerHTML)
    : { agents: [] })

  const [propertyMainText, setPropertyMainText] = useState('')

  const [slideshowData, setSlideshowData] = useState([])

  const [bounds, setBounds] = useState(null)
  const [map, setMap] = useState(null)
  const [maps, setMaps] = useState(null)

  const [isMobile, setIsMobile] = useState(!isServer ? window.innerWidth <= 800 : false)

  const mapRef = useRef(null)

  const [thumbnail, setThumbnail] = useState('')
  const [altThumbnail, setAltThumbnail] = useState('')

  useEffect(() => {
    if (property.address) {
      handleSetPropertyImages(property)
      let propertyText = document.getElementById('propertytext').innerHTML
      if (!propertyText.length) {
        propertyText = property.mainText
      }
      propertyText = propertyText.replace(/(?:\r\n)/g, '<br/>');
      setPropertyMainText(propertyText)
    } else {
      setPropertyNull(true)
    }
    if (!isServer) {
      window.addEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    if (!isServer) {
      if (window.innerWidth <= 800) {
        setIsMobile(true)
      } else {
        setIsMobile(false)
      }
    }
  }

  // function handleGetProperty() {
  //   let url = `properties/link/${str}`
  //   deprecatedURLs.forEach(match => {
  //     if (str === match.old) {
  //       url = `properties/link/${match.new}`
  //     }
  //   })
  //   getData(url, handleSetProperty, handleError)
  // }

  const handleSetPropertyImages = (property) => {
    property.agents && property.agents.sort((a, b) => {
      return a.position - b.position
    })
    setProperty(property)
    handleSetThumbnail(property)
    let ssData = handleSlideshowData(property)
    setSlideshowData(ssData)
  }

  const handleError = (err) => {
    if (err.status === 404) {
      setPropertyNull(true)
    }
  }

  const handleSetThumbnail = (property) => {
    let image = property.images.find(i => i.position === 1)
    let ogImage = image.storageKey
    let newImage;
    if (ogImage.includes('_1280-0')) {
      newImage = ogImage.replace('_1280-0', '_300-0')
    } else {
      newImage = ogImage.split('.').join('_300-0.')
    }
    setThumbnail(`${cloudFrontURL}/properties/${property.id}/images/${newImage}`)
    setAltThumbnail(`${cloudFrontURL}/properties/${property.id}/images/${ogImage}`)
  }


  const formatPrice = (price) => {
    var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'NZD',
      minimumFractionDigits: 0
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    let newPrice = formatter.format(price); /* $2,500.00 */
    newPrice = newPrice.replace(/NZ/g, '')
    return newPrice
  }


  const handleSlideshowData = (d) => {
    let newData = {}
    newData.bedrooms = d.bedrooms
    newData.bathrooms = d.bathrooms
    newData.images = []
    newData.showAddress = d.showAddress
    d.images.sort((a, b) => {
      return a.position - b.position;
    })
    d.images.map(img => {
      let url;
      if (img.storageKey.length) {
        url = `${cloudFrontURL}/properties/${d.id}/images/${img.storageKey}`
      } else {
        url = img.filename
      }
      newData.images.push(url)
      return newData
    })

    if (Number(d.floorArea) === 0) { d.floorArea = 0 }
    // Edge case if floorArea = "0.00"
    if (Number(d.landArea) === 0) { d.landArea = 0 }
    // Edge case if landArea = "0.00"

    newData.garages = d.garages
    newData.offstreetParking = d.offstreetParking
    newData.address = d.address
    newData.mainHeading = d.mainHeading.replace("&amp;", "&")
    newData.floorArea = d.floorArea
    newData.landArea = d.landArea
    newData.status = d.status
    return newData
  }

  const handleGoogleApiLoaded = (map, maps) => {
    renderMapMarkers(map, maps)
    setMap(map)
  }

  const renderMapMarkers = (map, maps) => {
    let addressStr = ''
    property.address.subNumber
      ? addressStr += property.address.subNumber + '/'
      : addressStr += ''

    addressStr += property.address.streetNumber + ' '
    addressStr += property.address.street + ', '
    addressStr += property.address.suburb + ', '
    addressStr += property.address.city + ' ' + property.address.postcode + ', '
    if (property.address.country.length > 2) {
      // AKA is country code
      addressStr += property.address.country
    } else {
      addressStr += lookup.byFips(property.address.country).country
    }
    let marker = new maps.Marker({
      position: { lat: property.locLatitude, lng: property.locLongitude },
      icon: '/images/GMapsMarker.png',
      map,
      title: addressStr
    })

    setBounds(map.getBounds())
    setMap(map)
    setMaps(maps)

    const infowindow = new maps.InfoWindow({
      content: addressStr,
    })

    marker.addListener("click", () => {
      infowindow.open(map, marker)
    })

  }


  const setSubject = () => {
    let subject = property.reaUniqueID
    if (property.address) {
      subject += ` - ${property.address.subNumber ? `${property.address.subNumber}/` : ''}${property.address.streetNumber} ${property.address.street} ${property.address.suburb}`
    }  
    return subject
  }

  const setBodyLink =() => {
    if (!isServer) {
     return window.location.href
    }
  }




  const openTel = (num) => {
    if (!isServer) {
      window.location.href = "tel:" + num;
    }
  }

  if (propertyNull) {
    return (
      <PropertyNotFound />
    )
  } else {
    return (
      <div className="property-container">
        <Helmet>
          <meta charSet="utf-8" />
        </Helmet>
        <div className="property-email-container">
          {/* <Link to="/" className="property-links"
            target="_blank" onClick={(event) => {
              if (!isServer) {
                event.preventDefault();
                window.open(`/property/email/agent/${property.agents[0].displayName}/${property.agents[0].email.trim()}/${property.reaUniqueID}/${property.url}`
                  , '', 'width=600,height=400,left=200,top=200');
              }
            }}>
            Email Agent
          </Link>
                  <Link to="/" className="property-links"
            target="_blank" onClick={(event) => {
              if (!isServer) {
                event.preventDefault();
                window.open(`/property/email/friend/${property.url}`
                  , '', 'width=600,height=400,left=200,top=200');
              }
            }}>
            Email to a Friend
          </Link> */}
          <div className="property-links"  onClick={() => openEmail(property.agents[0].email, isServer, `Property Enquiry: ${setSubject()}`, "")}>
            Email Agent
          </div>
          <div className="property-links"  onClick={() => openEmail('', isServer, `Here is a property from Kellands Real Estate for you!`, `Have a look at ${encodeURI(setBodyLink())}`)}>
          Email to a Friend
          </div>
  
          <Link className="property-links" to={{
            pathname: `/property/printview/${property.url}`,
            property: property
          }}>
            Print Friendly
          </Link>
        </div>
        <Slideshow data={slideshowData} propertyPage={true} />
        <div className="social-sharing-container row">
          <FacebookShareButton className="social-share-btn"
            url={!isServer ? window.location.href : '/'}
          >
            <FacebookIcon size={45} round={false} />
          </FacebookShareButton>
          <TwitterShareButton className="social-share-btn"
            url={!isServer ? window.location.href : '/'}
          >
            <TwitterIcon size={45} round={false} />
          </TwitterShareButton>
          <LinkedinShareButton className="social-share-btn"
            url={!isServer ? window.location.href : '/'}
          >
            <LinkedinIcon size={45} round={false} />
          </LinkedinShareButton>
        </div>
        <div className="property-main row">
          <div className="property-main-left">
            <div className="property-info-block property-main-text"
              dangerouslySetInnerHTML={{ __html: propertyMainText }}>
            </div>
            {!isMobile && property.agents ?
              <div className="property-info-block info-block-lastitem">
                <div className="info-block-header">
                  Agent Information
                </div>
                <div className="column">
                  {property.agents.map(agent => {
                    return (
                      <div key={agent.id} className="property-agents row">
                        <Link to={`/ourpeople/staff/${agent.displayName.replace(/ /g, '-')}`}>
                          <img className="property-agent-img agent-img"
                            alt={agent.displayName}
                            src={`${cloudFrontURL}/users/${agent.id}/images/${agent.storageKey}`} />
                        </Link>
                        <div className="agent-listing-container column">
                          <div className="agent-listing-name">
                            {agent.displayName}
                          </div>
                          <div className="agent-listing-numbers">
                            <div className="row">
                              <div className="agent-listing-number-header">
                                M
                              </div>
                              <div className="lighttext"
                                onClick={() => openTel(agent.mobile)}>
                                {agent.mobile}
                              </div>
                            </div>
                            <div className="row">
                              <div className="agent-listing-number-header">
                                P
                              </div>
                              <div className="lighttext"
                                onClick={() => openTel(agent.phone)}>
                                {agent.phone}
                              </div>
                            </div>
                          </div>
                          <div className="lighttext"
                            onClick={() => openEmail(agent.email, isServer, `Property Enquiry: ${setSubject()}`, "")}>
                            Email {agent.displayName}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                  {/* :  <div></div>  } */}
                </div>
              </div>
              :  <div></div>  }
          </div>
          <div className="property-main-right">
            {property.status !== 'sold' ?
              <div className="property-info-block">
                <div className="info-block-header">
                  {/* {property.saleMethod} */}
                  {(property.saleMethod === 'Deadline Private Treaty'
                    ? 'Deadline Sale'
                    : (property.saleMethod !== 'Tender')
                      && (property.saleMethod !== 'Auction')
                      ? 'For Sale'
                      : property.saleMethod)}
                </div>
                {/* {property.displaySaleDetails ?
                <div className="info-block-text">
                  {property.displaySaleDetails}
                </div>
                :  <div></div>  } */}
                <div className="info-block-text">
                  {/* TO DO - show auction fields if populated, regardless of saleMethod? */}
                  {/* TO DO - if property.saleMethod === 'Deadline Private Treaty', ignore this section? */}
                  {property.saleMethod === 'Auction'
                    ? property.auctionLocationOther
                      ? property.auctionLocationOther
                      : property.auctionLocation
                    : property.saleMethod === 'Tender' && property.auctionTime
                      ? `Tender closes ${formatTimes(property.auctionTime)}
                    on 
                     ${formatDate(property.auctionDate, true)} at ${property.auctionLocationOther ? property.auctionLocationOther : property.auctionLocation}`
                      : property.saleMethod === 'Fixed'
                        ? `Price: ${formatPrice(property.price)}`
                        : property.saleMethod === 'Offers/Enquiries Over' ?
                         `By ${property.saleMethod} ${formatPrice(property.price)}`
                        : property.saleMethod === 'For Sale'
                          ? property.byNegotiation
                            ? 'By Negotiation'
                            : ''
                          : property.saleMethod === 'Deadline Private Treaty'
                            ? ''
                            : `By ${property.saleMethod}`}
                </div>
                {property.auctionDate && property.auctionTime && property.saleMethod !== 'Tender' ?
                  <div className="info-block-text auction-block-text">
                    {`${formatDate(property.auctionDate, false)} at ${property.auctionTime && formatTimes(property.auctionTime)}`}
                  </div>
                  :  <div></div>  }
                <div className="info-block-text-sm">
                  {property.auctionRule
                    ? `(${property.auctionRule})`
                    : ''}
                </div>
              </div>
              : <div className="property-info-block">
                <div className="info-block-header">
                  Sold
                </div>
              </div>}
            {property.status !== 'sold' ?
              <div className="property-info-block">
                <div className="info-block-header">
                  Open Homes
                </div>
                {property.openhomes
                  ? property.openhomes.map(date => {
                    return (
                      <div className="info-block-text">
                        {`${formatDate(date.dayOn, false)}`}
                        {` ${date.startTime} -  ${date.endTime}`}
                      </div>
                    )
                  })
                  : <div className="info-block-text">
                    Please contact agent for appointment to view.
                  </div>}
              </div> : 
              <div></div>
              }
            {property.additionalFeatures ?
              <div className="property-info-block">
                <div className="info-block-header">
                  Features
                </div>
                {/* {property.additionalFeatures ? */}
                <div className="info-block-text">
                  <ul>
                    {property.additionalFeatures.map(f => {
                      return (
                        <li key={f.position} className="info-list-item">
                          {f.feature.replace("&amp;", "&")}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              :  <div></div>  
            }
            <div className={`property-info-block ${!isMobile ? 'info-block-lastitem' : ''}`}>
              <div className="info-block-header">
                {property.attachments && property.attachments.length
                  ? 'Attachments'
                  : ''}
              </div>
              {property.attachments ?
                <div className="info-block-inner">
                  {property.attachments.map(attachment => {
                    attachment.url && (attachment.url = attachment.url.replace("&amp;", "&"))
                    return (
                      attachment.mode === 'embed' || attachment.url && attachment.url.includes('matterport') ?
                        <iframe
                          title="attachment"
                          width="100%"
                          height="240"
                          // src={`${cloudFrontURL} /properties/${property.id}/attachments/${attachment.storageKey}`}?
                          src={
                            attachment.url.includes('youtube') && attachment.url.includes('watch?v=')
                              ? attachment.url.replace('watch?v=', 'embed/')
                              : attachment.url}
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen>
                        </iframe>
                        :
                        <ul>
                          <li>
                            <a className="lighttext"
                              href={`${cloudFrontURL}/properties/${property.id}/attachments/${attachment.storageKey}`}
                              download={`${attachment.mode === 'file'}`}>
                              {attachment.title}
                            </a>
                          </li>
                        </ul>
                    )
                  })}
                </div>
                :  <div></div>  }
              <div className="row property-web-id-container">
                <div className="property-web-id">
                  Web ID:
                </div>
                {` ${property.reaUniqueID}`}
              </div>
            </div>
            {
              isMobile ?
                <div className="property-info-block info-block-lastitem">
                  <div className="info-block-header">
                    Agent Information
                  </div>
                  <div className="column">
                    {property.agents.length ? property.agents.map(agent => {
                      return (
                        <div className="property-agents row">
                          <Link to={`/ourpeople/staff/${agent.displayName.replace(/ /g, '-')}`}>
                            <img className="property-agent-img agent-img"
                              alt="agent"
                              src={`${cloudFrontURL}/users/${agent.id}/images/${agent.storageKey}`} />
                          </Link>
                          <div className="agent-listing-container column">
                            <div className="agent-listing-name">
                              {agent.displayName}
                            </div>
                            <div className="agent-listing-numbers">
                              <div className="row">
                                <div className="agent-listing-number-header">
                                  M
                                </div>
                                <div className="lighttext"
                                  onClick={() => openTel(agent.mobile)}>
                                  {agent.mobile}
                                </div>
                              </div>
                              <div className="row">
                                <div className="agent-listing-number-header">
                                  P
                                </div>
                                <div className="lighttext"
                                  onClick={() => openTel(agent.phone)}>
                                  {agent.phone}
                                </div>
                              </div>
                            </div>
                            <div className="lighttext"
                              onClick={() => openEmail(agent.email, isServer, `Property Enquiry: ${setSubject()}`, "")}>
                              Email {agent.displayName}
                            </div>
                          </div>
                        </div>
                      )
                    })
                      :  <div></div>  }
                  </div>
                </div>
                :  <div></div>  
            }
          </div >
        </div >
        <div className="property-map-container google-map">
          <GoogleMapReact
            // id="map"
            ref={mapRef}
            options={map => ({
              mapTypeControl: true,
              streetViewControl: true,
              mapTypeIds: [
                map.MapTypeId.SATELLITE,
                map.MapTypeId.ROADMAP
              ],
              styles: [{
                featureType: "poi",
                stylers: [{ visibility: "on" }],
                elementType: "labels"
              }]
            })}
            bootstrapURLKeys={{
              key: 'AIzaSyC1YquZetiu2hje92mo-FyHLX7MS2-ZifI',
              libraries: ['places']
            }}
            center={{ lat: property.locLatitude, lng: property.locLongitude }}
            defaultCenter={{ lat: 0, lng: 0 }}
            zoom={property.locZoom}
            defaultZoom={15}
            bounds={bounds}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleGoogleApiLoaded(map, maps)}
          >
          </GoogleMapReact>
        </div>
      </div>
    )
  }
}

export default Property
